import styled from 'styled-components'
import { device } from '../../helpers/breakpoints'

const Title = styled.h1`
    padding: 0;
    margin: 0;
    font-family: "colombia regular";
    font-size: 9vw;
    color: #E2A246;
    text-align: center;
    margin-top: 15vw;
    margin-bottom: 15vw;
    font-weight: lighter;

    & span {
        font-family: "colombia regular italic";
    }

    @media ${device.tablet} {
        font-size: 45px;
        text-align: center;
        margin-top: 100px;
        margin-bottom: 100px;
    }
`

const TitleLeft = styled(Title)`
    text-align: center;

    @media ${device.tablet} {
        text-align: left;
    }
`

export { Title as default, TitleLeft }