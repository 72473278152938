import { useEffect } from 'react';
import { useState, useCallback } from 'react'
import Header from '../header'
import Footer from '../footer'
import { TitleLeft } from '../common/title'
import Content from '../common/content'
import realisationsConfig from '../assets/realisations-info.json'
import {
    useParams,
    Redirect
} from "react-router-dom";
import styled from 'styled-components'
import { device } from '../../helpers/breakpoints'
import ImageViewer from 'react-simple-image-viewer'
import Accueil from '../routes/accueil'

const Body = styled.div`
    margin-bottom: 15vw;
    margin-top: 15vw;

    @media ${device.tablet} {
        font-size: 20px;
        margin-bottom: 150px;
        margin-top: 0;
    }
`

const ImageWrapper = styled.div`
    width: 100%;
    padding-bottom: 100%; 
    margin-right: 0;
    margin-left: 0;
    

    display: inline-block;
    margin-top: 2%;
    margin-bottom: 2%;
    background-image: url(${props => props.picture});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: ${props => props.position != null ? props.position : "top left"};
    cursor: pointer;

    @media ${device.tablet} {
        width: 48%;
        padding-bottom: 48%; 
        margin-right: ${props => props.index % 2 == 1 ? "0" : "2%" };
        margin-left: ${props => props.index % 2 == 1 ? "2%" : "0" };
    }
`

const ImageViewerWrapper = styled.div`
    > * {
        padding: 0 !important;
        @media ${device.tablet} {
            padding: 0 60px 0 60px !important;
        }
    }
`


function RealisationDetail() {
    useEffect(() => {
        document.title = "Réalisations, LP Création réalise vos envies (local professionnel, aménagement maison feng shui, décoration d’intérieur bien-être)"
     }, []);

     useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    
    const openImageViewer = useCallback(index => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const { id } = useParams()
    const details = realisationsConfig[id]

    if(details === undefined) return (<Redirect
        to={{
          pathname: "/accueil"
        }}
      />)

    const photos = details.pictures.map(function(picture) {
        var pictureInfo = { 
            "component": require('../images/' + picture.name).default,
            "position": picture.position
        }
        return pictureInfo;
    })

    const imageViewerPhotos = photos.map(picture => picture.component)

    const photoComponents = photos.map((pictureInfo, index) => (<ImageWrapper picture={pictureInfo.component} position={pictureInfo.position} index={index} onClick={() => openImageViewer(index)} />))

    return (
        <>
            <Header backgroundImage={require('../images/' + details.headerDesktopPicture).default} backgroundImageMobile={require('../images/' + details.headerMobilePicture).default} />
            <Content>
                <TitleLeft>
                    {details.pageTitle}
                </TitleLeft>
                <Body>
                    {photoComponents}

                    {isViewerOpen && (
                        <ImageViewerWrapper>
                            <ImageViewer
                                src={imageViewerPhotos}
                                currentIndex={currentImage}
                                onClose={closeImageViewer}
                                backgroundStyle={{
                                    backgroundColor: "rgba(0,0,0,0.9)"
                                }}
                            />
                        </ImageViewerWrapper>
                    )}
                </Body>
            </Content>
            <Footer />
        </>
    )
}

export default RealisationDetail