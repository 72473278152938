import { useState } from 'react';
import Rodal from 'rodal';
import styled from 'styled-components'
import { device } from '../../helpers/breakpoints'
import FitText from '@kennethormandy/react-fittext'
import {
    Link
  } from "react-router-dom";

import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import 'rodal/lib/rodal.css';

const RealisationBlock = styled.div`
    margin: 0 auto;
    margin-bottom: 50px;
    width: 95%;
    display: flex;
    flex-direction: column;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 3px 2px rgba(227,227,227,0.48);

    @media ${device.laptop} {
        box-shadow: unset;
        border: unset;
        border-radius: unset;
        overflow: unset;
        flex-direction: row;
        height: 32vw;
        margin-bottom: 110px;
    }
`

const RealisationIllustrationWrapper = styled.div`
    height: 280px;

    @media ${device.laptop} {
        width: 50%;
        height: 100%;
    }
`

const RealisationIllustration = styled.div`
    height: 100%;
    background-image: url(${props => props.photo});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

    @media ${device.laptop} {
        margin-left: auto; 
        margin-right: 0;
        width: 32vw;
        background-position: center;
    }
`

const RealisationTextWrapper = styled.div`
    width: calc(100% - 30px);
    height: 100%;
    padding: 15px;

    @media ${device.laptop} {
        width: 50%;
        padding: unset;
    }
`

const RealisationText = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: relative;

    @media ${device.laptop} {
        padding-left: 1vw;
        width: 31vw;
    }
`

const CustomDesktopTitle = styled.p`
    margin: 0;
    color: #E2A246;
    font-family: "colombia bold";
    height: 5%;
    display: none;

    @media ${device.laptop} {
        display: unset;
    }
`

const CustomMobileTitle = styled.p`
    margin: 0;
    color: #E2A246;
    font-family: "colombia bold";
    display: unset;
    font-size: 5vw;
    margin-bottom: 5px;

    @media ${device.tablet} {
        font-size: 3vw;
    }


    @media ${device.laptop} {
        display: none;
    }
`

const CustomDesktopDescription = styled.p`
    margin: 0;
    font-family: "century gothic";
    color: #35626A;
    text-align: justify;
    display: none;

    @media ${device.laptop} {
        display: unset;
    }
`

const CustomMobileDescription = styled.p`
    display: unset;
    margin: 0;
    font-family: "century gothic";
    color: #35626A;
    text-align: justify;
    font-size: 4.2vw;
    height: ${props => (props.opened ? "unset" : "100px")};
    overflow: hidden;
    position: relative;

    &:after {
        content: "";
        position: absolute; top: 0; bottom: 0; left: -15px; right: -15px;
        box-shadow: ${props => (props.opened ? "unset" : "inset white 0 -19px 24px")};
    }

    @media ${device.tablet} {
        font-size: 2.5vw;
    }

    @media ${device.laptop} {
        display: none;
    }
`

const CustomFontIcon = styled(FontAwesomeIcon)`
    display: ${props => (props.opened ? "none" : "unset")};; 
    position: absolute;
    bottom: -10px;
    left: calc(50% - 15px);

    @media ${device.laptop} {
        display: none;
    }
`

const RodalContent = styled.div`
    font-family: "century gothic";
    color: #35626A;
    font-weight: lighter;
    font-size: 26px;
    line-height: 29px;
    margin: 0 auto;
    text-align: justify;
    padding: 40px;
`

function TextAbstract(text, length, extended) {
    if (text == null) {
        return "";
    }
    if (text.length <= length) {
        return (<div>{text}</div>);
    }
    text = text.substring(0, length);
    const last = text.lastIndexOf(" ");
    text = text.substring(0, last);
    return (<div>{text} {extended}</div>);
}

function Card(props) {
    const [opened, setOpen] = useState(false)
    const [visible, setVisible] = useState(false)
    
    const onClick = () => setVisible(true)
    const close = () => setVisible(false)

    const max = 955
    const extended = (<span onClick={onClick}>[...]</span>)
    const text = TextAbstract(props.description, max, extended)

    return (
        <div>
            <RealisationBlock>
                <RealisationIllustrationWrapper>
                    <Link to={"/realisations/" + props.urlPart}>
                        <RealisationIllustration photo={require('../images/' + props.photo).default} />
                    </Link>
                </RealisationIllustrationWrapper>
                <RealisationTextWrapper>
                    <RealisationText>
                        <CustomDesktopTitle>
                            <FitText compressor={2}>{props.title}</FitText>
                        </CustomDesktopTitle>
                        <CustomMobileTitle>
                            {props.title}
                        </CustomMobileTitle>
                        <CustomDesktopDescription>
                            <FitText compressor={2.46}>{text}</FitText>
                        </CustomDesktopDescription>
                        <CustomMobileDescription onClick={() => setOpen(!opened)} opened={opened}>
                            {props.description}
                        </CustomMobileDescription>
                        <CustomFontIcon onClick={() => setOpen(!opened)} opened={opened} icon={faChevronDown} style={{ color: '#B7B7B7', fontSize: "30px" }} />
                    </RealisationText>
                </RealisationTextWrapper>
                <Rodal 
                width={80} 
                height={60} 
                measure={"%"} 
                visible={visible} 
                animation={"slideLeft"} 
                duration={600} 
                onClose={close}>
                    <RodalContent>
                        {props.description}
                    </RodalContent>
                </Rodal>
            </RealisationBlock>
        </div>
    )
}

export default Card