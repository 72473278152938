import styled from 'styled-components'
import { device } from '../../helpers/breakpoints'

const Content = styled.div`
    width: 90%;
    margin-left: 5%;

    @media ${device.tablet} {
        width: 80%;
        margin-left: 10%;
    }
`

export default Content