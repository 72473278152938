import React from 'react';
import styled from 'styled-components'
import mainLogo from '../images/main-logo.png'
import mainLogoMobile from '../images/main-logo-mobile.png'
import { device } from '../../helpers/breakpoints'
import {
    Link
  } from "react-router-dom";

import NavBar from './navbar'

var HeaderWrapper = styled.div`
    height: 50vh;
    position: relative;

    @media ${device.laptop} {
        height: 70vh;
    }
`

var HeaderGradient = styled.div`
    z-index: -2;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, 
        rgba(53, 98, 106, 0), 
        rgba(53, 98, 106, 0.2),
        rgba(53, 98, 106, 1), 
        rgba(53, 98, 106, 1), 
        rgba(53, 98, 106, 1), 
        rgba(53, 98, 106, 1));

    @media ${device.tablet} {
        background-image: linear-gradient(to top, 
        rgba(53, 98, 106, 0), 
        rgba(53, 98, 106, 0.1), 
        rgba(53, 98, 106, 0.5),
        rgba(53, 98, 106, 0.8),
        rgba(53, 98, 106, 1),
        rgba(53, 98, 106, 1), 
        rgba(53, 98, 106, 1), 
        rgba(53, 98, 106, 1));
    }
`

var HeaderBackground = styled.div`
    position: absolute;
    z-index: -3;
    top: 0;
    width: 100%;
    height: 100%;

    background-image: url(${props => props.backgroundImageMobile});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom;

    @media ${device.tablet} {
        background-position: ${props => props.backgroundPosition};
        background-image: url(${props => props.backgroundImage});
    }
`

var HeaderLogo = styled.div`
    position: absolute;
    top: 2%;
    left: 0;     
    width: 100%;
    height: 55%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-image: url(${mainLogoMobile});
    background-position: center center;
    z-index: 1;

    @media ${device.tablet} {
        top: 1.5%;
        left: 1.5%; 
        width: 90%;
        height: 40%;
        background-image: url(${mainLogo});
        background-position: left top;
        background-size: auto 100%;
    }
`

function Header(props) {
    return (
        <HeaderWrapper>
            <HeaderGradient />
            <HeaderBackground backgroundImage={props.backgroundImage} backgroundImageMobile={props.backgroundImageMobile} backgroundPosition={props.backgroundPosition} />
            <Link to="/accueil">
                <HeaderLogo />
            </Link>
            <NavBar />
        </HeaderWrapper>
    )
}

export default Header