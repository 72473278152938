import React, { useState } from 'react';
import Rodal from 'rodal';
import styled from 'styled-components'
import { device } from '../../helpers/breakpoints'

import fbLogo from '../images/fb.png'
import instaLogo from '../images/insta.png'
import linkedinLogo from '../images/linkedin.png'

import Title from '../common/title'

import 'rodal/lib/rodal.css';

import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FooterWraper = styled.div`
    height: 100px;
    background-color: #35626A;
`

const SocialsWrapper = styled.div`
    padding-top: 20px;
    display: flex;
    justify-content: center;
`

const Social = styled.a`
    width: 25px;
    height: 25px;
    margin: 0 12px 0 12px;
`

const Legal = styled.p`
    cursor: pointer;
    font-family: "century gothic";
    color: white;
    font-size: 13px;
    text-align: center; 
`

const RodalContent = styled.div`
    width: 100%;
    height: calc(100% - 30px);
    padding: 30px 0 0 0;

    @media ${device.tablet} {
        width: calc(100% - 90px);
        height: calc(100% - 50px);
        padding: 25px 45px 25px 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`

const RodalTitle = styled(Title)`
    margin: 0 0 30px 0;

    @media ${device.tablet} {
        margin: 0 0 60px 0;
    }
`

const RodalBody = styled.div`
    display: flex;
    flex-direction: column;
`

const RodalRow = styled.div`
    display: flex;
    margin-bottom: 8px;
`

const RodalRowTitle = styled.p`
    flex: 1;
    margin: 0;
    padding: 0;
    font-family: "century gothic";
    color: #35626A;
    font-size: 3.5vw;
    line-height: 4vw;
    font-weight: 700;

    @media ${device.tablet} {
        font-size: 26px;
        line-height: 26px;
    }
`

const RodalRowBody = styled.p`
    flex: 2;
    margin: 0;
    padding: 0 0 0 10px;
    font-family: "century gothic";
    color: #35626A;
    font-size: 4vw;
    line-height: 4vw;
    font-weight: lighter;

    & span {
        font-size: 2.5vw;
        font-style: italic;
        line-height: 2.9vw;
        display: inline-block;
    }

    @media ${device.tablet} {
        font-size: 26px;
        line-height: 26px;

        & span {
            font-size: 15px;
            line-height: 16px;
            display: inline-block;
            font-style: italic;
        }
    }
`

function Footer() {
    const [visible, setVisible] = useState(false)
    const onClick = () => setVisible(true)
    const close = () => setVisible(false)

    return (
        <FooterWraper>
            <SocialsWrapper>
                <Social target="_blank" href="https://www.facebook.com/lpcreation.deco">
                    <FontAwesomeIcon icon={faFacebookSquare} style={{ color: '#8f0802', fontSize: "30px" }} />
                </Social>
                <Social target="_blank" href="https://www.instagram.com/lpcreation.deco/">
                    <FontAwesomeIcon icon={faInstagram} style={{ color: '#8f0802', fontSize: "30px" }} />
                </Social>
                <Social target="_blank" href="https://www.linkedin.com/company/lp-cr%C3%A9ation">
                    <FontAwesomeIcon icon={faLinkedin} style={{ color: '#8f0802', fontSize: "30px" }} />
                </Social>
            </SocialsWrapper>
            <Legal onClick={onClick}>
                © 2021 LP CREATION | Mentions légales
            </Legal>
            <Rodal 
                width={80} 
                height={60} 
                measure={"%"} 
                visible={visible} 
                animation={"slideLeft"} 
                duration={600} 
                onClose={close}>
                <RodalContent>
                    <RodalTitle>MENTIONS LEGALES</RodalTitle>
                    <RodalBody>
                        <RodalRow>
                            <RodalRowTitle>Nom :</RodalRowTitle>
                            <RodalRowBody>
                                Laura Pagès _ LP CREATION<br />
                                <span>(Dispensé d’immatriculation au registre du commerce et des sociétés (RCS) et au répertoire des métiers (RM))</span>
                            </RodalRowBody>
                        </RodalRow>
                        <RodalRow>
                            <RodalRowTitle>Adresse mail :</RodalRowTitle>
                            <RodalRowBody>contact@lp-creation.com</RodalRowBody>
                        </RodalRow>
                        <RodalRow>
                            <RodalRowTitle>Numéro de téléphone :</RodalRowTitle>
                            <RodalRowBody>+33 (0)6 73 17 66 12</RodalRowBody>
                        </RodalRow>
                        <RodalRow>
                            <RodalRowTitle>Adresse postale :</RodalRowTitle>
                            <RodalRowBody>86 RUE VICTOR HUGO 94700 MAISONS-ALFORT FRANCE</RodalRowBody>
                        </RodalRow>
                        <RodalRow>
                            <RodalRowTitle>Hébergeur :</RodalRowTitle>
                            <RodalRowBody>
                                SCALEWAY SAS BP 438 75366 PARIS CEDEX 08 FRANCE<br />
                                +33 (0)1 84 13 00 00
                            </RodalRowBody>
                        </RodalRow>
                    </RodalBody>
                </RodalContent>
            </Rodal>
        </FooterWraper>
    )
}

export default Footer