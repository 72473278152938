import { useEffect } from 'react';
import Header from '../header'
import Footer from '../footer'
import backgroundImage from '../images/qui-suis-je-image.jpg'
import backgroundImageMobile from '../images/qui-suis-je-image-mobile.jpg'
import institutImage from '../images/institut.png'
import enfanceImage from '../images/enfance.png'
import { TitleLeft } from '../common/title'
import Title from '../common/title'
import Content from '../common/content'
import styled from 'styled-components'
import { device } from '../../helpers/breakpoints'
import {
    Link
  } from "react-router-dom";
import FalseInterrogation from '../common/interrogation'

const Body = styled.p`
    margin: 0;
    padding: 0;
    font-family: "century gothic";
    color: #35626A;
    font-size: 5vw;
    font-weight: lighter;
    text-align: justify;

    margin-bottom: 15vw;
    margin-top: 15vw;

    @media ${device.tablet} {
        font-size: 20px;
        margin-bottom: 150px;
        margin-top: 0;
    }
`

var PartnerBlock = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    @media ${device.tablet} {
        flex-direction: row;
    }
`

var PartnerElement = styled.div`
    display: flex;
    flex-direction: column;
    @media ${device.tablet} {
        &:first-of-type {
            padding-right: 60px;
        }
        &:last-of-type {
            padding-left: 60px;
        }
    }
`

var LogoWrapper = styled.div`
    width: 150px;
    height: 150px;
    margin: 0 auto;
    margin-bottom: 50px;

    @media ${device.tablet} {
        margin-top: 50px;
        margin-bottom: 0;
    }
`

var Logo = styled.div`
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center center;
    z-index: 1;
    margin-top: 20px;

    @media ${device.tablet} {
        margin-top: 0;
    }
`

var InstitutLogo = styled(Logo)`
    background-image: url(${institutImage});
`

var EnfanceLogo = styled(Logo)`
    background-image: url(${enfanceImage});
`


function QuiSuisJe() {
    useEffect(() => {
        document.title = "Qui suis-je ? Designer d’espace décoratrice d’intérieur consultant Feng Shui"
     }, []);
     
    return (
        <>
            <Header backgroundImage={backgroundImage} backgroundImageMobile={backgroundImageMobile} backgroundPosition={"center"} />
            <Content>
                <TitleLeft>QUI SUIS-JE<FalseInterrogation>?</FalseInterrogation></TitleLeft>
                <Body>
                    Une designer d’espace diplômée d’état, formée au Feng Shui 
                    auprès de l’Institut Pierre Thirault, et passionnée depuis enfant par 
                    les arts plastiques et créatifs. D’aussi longtemps que je me souvienne, 
                    j’ai toujours attaché plus d’importance à la façon dont les usagers 
                    allaient ressentir un espace, qu’à ce qu’ils allaient en voir. Après 
                    plusieurs années auprès de différentes structures, tels qu’un cuisiniste 
                    de luxe ou encore un constructeur de maisons individuelles, j’ai été 
                    confrontée au décalage entre ma vision de l’espace et ma mission 
                    auprès de mes employeurs : quelle est la place de l’humain dans le 
                    processus créatif<FalseInterrogation>?</FalseInterrogation> Malheureusement trop peu présente, à mon goût, 
                    dans nos sociétés occidentales. C’est alors que j’ai découvert le Feng 
                    Shui.<br />
                    LP Création est né de ce désir de replacer l’usager d’un lieu au 
                    centre de la création architecturale, afin de créer des lieux empreints 
                    d’harmonie, procurant bien-être et sérénité.
                    <br /><br />

                    <Title>NOS PARTENAIRES</Title>

                    <PartnerBlock>
                        <PartnerElement>
                            <p>
                                Depuis 2021, je fais partie du réseau 
                                de consultants formateurs agréés de l’Institut Pierre Thirault. Un gage 
                                de sérieux dans la prestation que je vous propose mais surtout 
                                l’assurance d’une équipe forte de plus de 20 ans d’expérience au service 
                                de votre bien-être.
                            </p>
                            <LogoWrapper>
                                <Link to={{ pathname: "https://institut-pierre-thirault.fr/" }} target="_blank">
                                    <InstitutLogo />
                                </Link>
                            </LogoWrapper>
                        </PartnerElement>
                        <PartnerElement>
                            <p>
                                Depuis 2022, j’ai rejoins le Collectif 
                                Enfance made in France, qui regroupe tant des thérapeutes, artisanes, 
                                spécialistes du bien-être et prestataires diverses qui accompagnent les 
                                jeunes parents dans leur projet de parentalité, de la conception aux 
                                premiers mois de bébé.
                            </p>
                            <LogoWrapper>
                                <Link to={{ pathname: "https://enfancemadeinfrance.com/" }} target="_blank">
                                    <EnfanceLogo />
                                </Link>
                            </LogoWrapper>
                        </PartnerElement>
                    </PartnerBlock>
                </Body>
            </Content>
            <Footer />
        </>
    )
}

export default QuiSuisJe