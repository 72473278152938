import { useEffect } from 'react';
import Header from '../header'
import Footer from '../footer'
import styled from 'styled-components'
import backgroundImage from '../images/contact-image.jpg'
import backgroundImageMobile from '../images/contact-image-mobile.jpg'
import { TitleLeft } from '../common/title'
import Content from '../common/content'
import { device } from '../../helpers/breakpoints'
import { Formik } from "formik";
import * as Yup from "yup";
import emailjs from 'emailjs-com';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const SplittedPanel = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    @media ${device.laptop} {
        flex-direction: row;
    }
`

const Panel = styled.div`
    flex: 1 1 50%;
    box-sizing: border-box;

    :last-of-type {
        margin-bottom: 40px;
    }

    @media ${device.laptop} {
        :first-of-type {
            padding-right: 20vw;
        }

        :last-of-type {
            padding-left: 8vw;
            margin-bottom: 0;
        }
    }
`

const PanelTitle = styled.p`
    font-family: "century gothic";
    color: #E2A246;
    font-size: 4.5vw;
    margin: 0;
    padding: 0;
    font-weight: 1000;

    @media ${device.tablet} {
        font-size: 20px;
    }
`

const PanelBody = styled.p`
    margin: 0;
    padding: 0;
    font-family: "century gothic";
    color: #35626A;
    font-size: 5vw;
    font-weight: lighter;
    text-align: left;

    @media ${device.tablet} {
        font-size: 20px;
    }
`

const LeftPanelBody = styled(PanelBody)`
    text-align: justify;
`

const CustomInputGroup = styled.div`
    margin-top: 40px;

    :last-of-type {
        margin-bottom: 150px;
    }
`

const CustomInputTitle = styled.p`
    margin: 0 0 10px;
    padding: 0;

    font-family: "century gothic";
    color: #35626A;
    font-size: 5vw;
    font-weight: lighter;

    @media ${device.tablet} {
        font-size: 20px;
    }
`

const CustomInput = styled.input`
    border: 2px solid #8f0802;
    outline: none;
    width: 100%;
    height: 45px;
    box-sizing: border-box;
    padding: 12px 20px;
    font-family: "century gothic";
    color: #35626A;
    font-size: 4.5vw;
    font-weight: lighter;

    @media ${device.tablet} {
        font-size: 20px;
        width: 24.5vw;
    }
`

const CustomTextArea = styled.textarea`
    border: 2px solid #8f0802;
    outline: none;
    width: 100%;
    height: 400px;
    box-sizing: border-box;
    padding: 12px 20px;
    font-family: "century gothic";
    color: #35626A;
    font-size: 4.5vw;
    font-weight: lighter;
    resize: none;

    @media ${device.tablet} {
        font-size: 20px;
        width: 50vw;
    }
`

const CustomButton = styled.button`
    padding: 0;
    margin: 0;
    background-color: #8f0802;
    border: 2px solid #b83b35;
    width: 170px;
    height: 60px;
    font-family: "colombia regular";
    font-size: 30px;
    line-height: 30px;
    color: #E2A246;
    font-weight: lighter;
    cursor: pointer;
`

const ButtonWrapper = styled.div`
    text-align: center; 

    @media ${device.tablet} {
        text-align: left; 
    }
`

const CustomMailError = styled.p`
    font-family: "colombia regular";
    position: absolute;
    margin: 0;
    padding: 0;
    width: 90%;
    text-align: right;
    color: #E2A246;
    font-weight: bold;
    font-style: italic;

    @media ${device.tablet} {
        width: 24.5vw;
    }
`

const CustomMailErrorForTextArea = styled(CustomMailError)`
    width: 90%;

    @media ${device.tablet} {
        width: 50vw;
    }
`

const CustomInputError = styled.span`
    font-family: "century gothic";
    color: #E2A246;
    font-size: 5vw;
    font-weight: bold;
    margin-left: 3px;

    @media ${device.tablet} {
        font-size: 20px;
    }
`

function Contact() {
    useEffect(() => {
        document.title = "Contact, LP Création Laura Pagès, conseiller feng shui décorateur d’intérieur Ile de France Paris"
     }, []);

    const phoneRegExp = /^(\+33|0033|0)(6|7)[0-9]{8}$/

    return (
        <>
            <Header backgroundImage={backgroundImage} backgroundImageMobile={backgroundImageMobile} backgroundPosition={"bottom 40% center"} />
            <Content>
                <TitleLeft>CONTACTEZ-NOUS</TitleLeft>
                <SplittedPanel>
                    <Panel>
                        <PanelTitle>FORMULAIRE DE CONTACT</PanelTitle>
                        <LeftPanelBody>
                            Pour toute demande, laissez-nous 
                            vos coordonnées ainsi que votre 
                            message, nous vous répondrons 
                            dans les plus brefs délais.
                        </LeftPanelBody>
                    </Panel>
                    <Panel>
                        <PanelTitle>COORDONNEES</PanelTitle>
                        <PanelBody>
                            <i>LP Création</i><br />
                            Mme Laura Pagès<br />
                            <b>Téléphone :</b> +33 (0)6 73 17 66 12<br />
                            <b>Email :</b> contact@lp-creation.com
                        </PanelBody>
                    </Panel>
                </SplittedPanel>
                <Formik
                initialValues={{ name: "", email: "", phone: "", message: "" }}
                onSubmit={async (values, actions) => {                    
                    var data = {
                        name: values.name,
                        email: values.email,
                        phone: values.phone,
                        message: values.message
                    }

                    emailjs.send('service_s9zmzmp', 'template_uzmm3x7', data, 'user_x2ZGav7HqARzitRkVOGjE').then(
                        function () {
                            NotificationManager.info('Votre message a bien été envoyé !');
                            actions.resetForm();
                        },
                        function () {
                            NotificationManager.error('Un soucis est arrivé pendant l\'envoi de votre message...', 'Erreur!');
                        }
                    );
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string()
                    .required("champ obligatoire")
                    .min(2, "veuillez indiquer un nom valide"),
                    email: Yup.string()
                    .required("champ obligatoire")
                    .email("veuillez indiquer un mail valide"),
                    phone: Yup.string()
                    .required("champ obligatoire")
                    .matches(phoneRegExp, "veuillez indiquer un numéro de téléphone valide"),
                    message: Yup.string()
                    .required("champ obligatoire")
                    .min(2, "veuillez indiquer un message valide")
                })}
                >
                {props => {
                    const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    } = props;
                    return (
                    <form onSubmit={handleSubmit}>
                        <CustomInputGroup>
                            <CustomInputTitle>
                                Nom et prénom
                                {errors.name && touched.name && (
                                    <CustomInputError>*</CustomInputError>
                                )}
                                </CustomInputTitle>
                            <CustomInput 
                            id="name"
                            type="text"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            {errors.name && touched.name && (
                                <CustomMailError>{errors.name}</CustomMailError>
                            )}
                        </CustomInputGroup>
                        <CustomInputGroup>
                            <CustomInputTitle>
                                Email
                                {errors.email && touched.email && (
                                    <CustomInputError>*</CustomInputError>
                                )}
                            </CustomInputTitle>
                            <CustomInput
                            id="email"
                            type="text"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            {errors.email && touched.email && (
                                <CustomMailError>{errors.email}</CustomMailError>
                            )}
                        </CustomInputGroup>
                        <CustomInputGroup>
                            <CustomInputTitle>
                                Numéro de téléphone
                                {errors.phone && touched.phone && (
                                    <CustomInputError>*</CustomInputError>
                                )}
                            </CustomInputTitle>
                            <CustomInput
                            id="phone"
                            type="text"
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            {errors.phone && touched.phone && (
                                <CustomMailError>{errors.phone}</CustomMailError>
                            )}
                        </CustomInputGroup>
                        <CustomInputGroup>
                            <CustomInputTitle>
                                Votre message
                                {errors.message && touched.message && (
                                    <CustomInputError>*</CustomInputError>
                                )}
                            </CustomInputTitle>
                            <CustomTextArea 
                            id="message"
                            type="text"
                            value={values.message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            {errors.message && touched.message && (
                                <CustomMailErrorForTextArea>{errors.message}</CustomMailErrorForTextArea>
                            )}
                        </CustomInputGroup>
                    
                        <CustomInputGroup>
                            <ButtonWrapper>
                                <CustomButton type="submit" disabled={isSubmitting}>ENVOYER</CustomButton>
                            </ButtonWrapper>
                        </CustomInputGroup>
                    </form>
                    );
                }}
                </Formik>
            </Content>
            <Footer />
            <NotificationContainer />
        </>
    )
}

export default Contact