import { useEffect } from 'react';
import Header from '../header'
import Footer from '../footer'
import { TitleLeft } from '../common/title'
import Content from '../common/content'
import backgroundImage from '../images/realisations-image.jpg'
import backgroundImageMobile from '../images/realisations-image-mobile.jpg'
import realisationsConfig from '../assets/realisations-info.json'
import styled from 'styled-components'
import { device } from '../../helpers/breakpoints'
import Card from '../card'

const Body = styled.p`
    margin-top: 15vw;
    margin-bottom: 15vw;

    @media ${device.tablet} {
        margin-bottom: 150px;
        margin-top: 0;
    }
`

function Realisations() {
    useEffect(() => {
        document.title = "Réalisations, LP Création réalise vos envies (local professionnel, aménagement maison feng shui, décoration d’intérieur bien-être)"
     }, []);
     
    const cards = Object.keys(realisationsConfig).map(
        key => (<Card urlPart={key} title={realisationsConfig[key].listTitle} description={realisationsConfig[key].description} photo={realisationsConfig[key].illustrationPicture} />))

    return (
        <>
            <Header backgroundImage={backgroundImage} backgroundImageMobile={backgroundImageMobile} />
            <Content>
                <TitleLeft>RÉALISATIONS</TitleLeft>
                <Body>
                    {cards}
                </Body>
            </Content>
            <Footer />
        </>
    )
}

export default Realisations