import React, { useState } from 'react';
import styled from 'styled-components'
import { device } from '../../helpers/breakpoints'
import { BsList } from 'react-icons/bs';
import { IconContext } from "react-icons";
import {
    Link
  } from "react-router-dom";

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`

const StyledList = styled.ul`
    z-index: 1;
    position: absolute;
    top: 55%;
    list-style: none;
    display: ${props => (props.opened ? "flex" : "none")};
    flex-direction: column;
    width: 100%;
    text-align: center;
    padding: 30px 0 30px 0;
    background-color: #35626A;
    
    @media ${device.tablet} {
        background-color: transparent;
        padding: 0;
        min-height: 0;
        display: table;
        text-align: center;
        border-spacing: 2vw 0;
        top: 34%;
        right: 1%;
        width: auto;
        height: auto;
    }

    @media ${device.laptop} {
        top: 36%;
    }

    @media ${device.desktop} {
        top: 37%;
    }
`

const StyledListElement = styled.li`
    display: table-cell; 
    position: relative; 
    margin-bottom: 15px;
    margin-top: 15px;

    @media ${device.tablet} {
        margin-bottom: 0;
        margin-top: 0;
    }
`

const StyledListElementLink = styled(Link)`
    text-decoration: none;
    color: #E2A246;
    font-family: "colombia bold";
    font-size: 1rem;

    @media ${device.tablet} {
        font-size: 0.9rem;
        &:after {
            background: none repeat scroll 0 0 transparent;
            bottom: -10;
            content: "";
            display: block;
            height: 2px;
            left: 50%;
            position: absolute;
            background: #E2A246;
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
            width: 0;
        }

        &:hover:after {
            width: 100%; 
            left: 0; 
        }
    }

    @media ${device.laptop} {
        font-size: 1.2rem;
    }

    @media ${device.laptopL} {
        font-size: 1.5rem;
    }

    @media ${device.desktop} {
        font-size: 2.3rem;
    }
`

const StyledIconWrapper = styled.div`
    z-index: 10;
    position: absolute;
    bottom: 40%;
    right: 3%;
    cursor: pointer;

    @media ${device.tablet} {
        display: none;
    }
`

function NavBar() {
    const [opened, setOpen] = useState(false)
    
    return (
        <Wrapper>
        <StyledList opened={opened}>
            <StyledListElement><StyledListElementLink to="/accueil">ACCUEIL</StyledListElementLink></StyledListElement>
            <StyledListElement><StyledListElementLink to="/qui-suis-je">QUI SUIS-JE ?</StyledListElementLink></StyledListElement>
            <StyledListElement><StyledListElementLink to="/prestations">PRESTATIONS</StyledListElementLink></StyledListElement>
            <StyledListElement><StyledListElementLink to="/realisations">RÉALISATIONS</StyledListElementLink></StyledListElement>
            <StyledListElement><StyledListElementLink to="/contact">CONTACT</StyledListElementLink></StyledListElement>
        </StyledList>
        <IconContext.Provider value={{ color: "#E2A246", size: "2.4em" }}>
            <StyledIconWrapper onClick={() => setOpen(!opened)}>
                <BsList />
            </StyledIconWrapper>
        </IconContext.Provider>
        </Wrapper>
    )
}

export default NavBar