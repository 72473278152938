import { useEffect } from 'react';
import Header from '../header'
import Footer from '../footer'
import FalseInterrogation from '../common/interrogation'
import styled from 'styled-components'
import Title from '../common/title'
import Content from '../common/content'
import { device } from '../../helpers/breakpoints'
import backgroundImage from '../images/prestations-image.jpg'
import backgroundImageMobile from '../images/prestations-image-mobile.jpg'
import firstPrestation from '../images/prestations-1.jpg'
import secondPrestation from '../images/prestations-2.jpg'
import thirdPrestation from '../images/prestations-3.jpg'
import fourthPrestation from '../images/prestations-4.jpg'
import fifthPrestation from '../images/prestations-5.jpg'

const PhaseHeader = styled.div`
    display: flex;
    justify-content: space-between;
    line-height: 23px;
    flex-direction: column;
`

const PhaseTitlePart = styled.p`
    margin: 0;
    padding: 0;
    font-family: "century gothic";
    color: #E2A246;
    font-size: 5vw;
    text-align: left;
    line-height: 6vw;

    @media ${device.tablet} {
        font-size: 23px;
        line-height: 24px;
    }
`

const PhasePricePart = styled.p`
    margin: 0;
    padding: 0;
    font-family: "century gothic";
    color: #E2A246;
    font-size: 4vw;
    line-height: 4.5vw;
    text-align: right;
    font-weight: bold;
    font-style: italic;

    @media ${device.tablet} {
        font-size: 19px;
        line-height: 20px;
    }

    @media ${device.laptop} {
        font-size: 20px;
        line-height: 21px;
    }
`

const PhaseSpace = styled.div`
    border-bottom: 1px solid #E2A246;
    flex: 1;
    margin-bottom: 0.7vw;
    margin-top: 0.7vw;

    @media ${device.laptop} {
        margin-bottom: 0;
        margin-top: 0;
    }
`

const PhaseDescription = styled.p`
    margin: 0;
    padding: 0;
    font-family: "century gothic";
    color: #35626A;
    font-size: 5vw;
    text-align: justify;
    white-space: pre-wrap;
    margin-bottom: 4vw;

    @media ${device.tablet} {
        font-size: 20px;
        margin-bottom: 2vh;
    }
`

const PrestationExamples = styled.ul`
    padding: 0;
    margin: 0;
    margin-top: 15vw;
    margin-bottom: 15vw;
    display: flex;
    list-style-type: none;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @media ${device.tablet} {
        margin-top: 150px;
        margin-bottom: 160px;
    }

    @media ${device.laptop} {
        flex-wrap: nowrap;
    }
`

const PrestationExampleWrapper = styled.li`
    width: 30vw;
    height: 30vw;
    text-align: center;
    overflow: hidden;

    @media ${device.tablet} {
        padding: 0;
        width: 24vw;
        height: 24vw;
    }

    @media ${device.laptop} {
        width: auto;
        height: auto;
    }
`

const PrestationExample = styled.img`
    width: calc(100% - 1vw);
    padding: 0.5vw;

    @media ${device.laptop} {
        width: calc(100% - 0.4vw);
        padding: 0.2vw;
    }
`

const LastPhase = styled.div`
    margin-top: 15vw;
    margin-bottom: 15vw;

    @media ${device.tablet} {
        margin-top: 150px;
        margin-bottom: 150px;
    }
`

const LastPhaseTitle = styled(PhaseTitlePart)`
    text-align: justify;
    text-align-last: justify;
    margin-bottom: 20px;
`

const PricesBlock = styled.div`
    margin-bottom: 4vw;

    @media ${device.tablet} {
        margin-bottom: 19px;
    }

    @media ${device.laptop} {
        margin-bottom: 20px;
    }
`

function Phase(props) {
    return (
        <>
            <PhaseHeader>
                <PhaseTitlePart>{props.title}</PhaseTitlePart>
                <PhaseSpace />
                <PricesBlock>
                    {props.prices.map(price => {
                        return (
                            <PhasePricePart>{price}</PhasePricePart>
                        )
                    })}
                </PricesBlock>
            </PhaseHeader>
            <PhaseDescription>{props.description}</PhaseDescription>
        </>
    )
}

function ExampleList(props) {
    return (
        <PrestationExamples>
            {props.prestationsExample.map(element => {
                return (
                    <PrestationExampleWrapper>
                        <PrestationExample src={element} />
                    </PrestationExampleWrapper>    
                )
            })}
        </PrestationExamples>
    )
}

function Prestations() {
    useEffect(() => {
        document.title = "Prestations, LP Création vous accompagne dans la réalisation de vos projets (aménagement, décoration, shopping déco, analyse feng shui, suivi de chantier)"
     }, []);

    const prestationsExample = [
        firstPrestation,
        secondPrestation,
        thirdPrestation,
        fourthPrestation, 
        fifthPrestation
    ]

    return (
        <>
            <Header backgroundImage={backgroundImage} backgroundImageMobile={backgroundImageMobile} backgroundPosition={"bottom 20% center"} />
            <Content>
                <Title>PARTICULIERS OU PROFESSIONNELS, LP CRÉATION VOUS ACCOMPAGNE DANS LA RÉALISATION DE VOS PROJETS...</Title>
                <Phase
                    title={"PHASE 1 : La visite conseil à domicile"}
                    prices={["80€/heure"]}
                    description={"Cette première rencontre permet de définir vos besoins et vos envies ainsi que la faisabilité de votre projet. Nous vous proposerons des conseils d’aménagement et de décoration. La visite sera déduite du montant de l'étude."}
                />
                <Phase
                    title={"PHASE 2 : Étude d'Aménagement"}
                    prices={["<50 m² 25€/m²", "50<100m² 20€/m²", "Au delà de 100m² 15€/m²"]}
                    description={"Cette étape inclut la prise de cote sur site et l’établissement d’un dossier de plans 2D, et vues 3D avec un niveau de détail sommaire. Nous vous accompagnons dans la création d’un nouvel espace, afin de repenser la distribution des pièces, supprimer et créer des cloisons. En résumé, nous définissons les limites de vos espaces."}
                />
                <Phase
                    title={"PHASE 3 : Étude de Décoration"}
                    prices={["<50 m² 35€/m²", "50<100m² 30€/m²", "Au delà de 100m² 25€/m²"]}
                    description={"Cette étape inclut la prise de cote sur site et l’établissement d’un dossier intégrant plans et vues 3D avec niveau de détail élevé, plans de mobilier sur mesure si besoin, ainsi que recherche de matériaux, couleurs et matières avec fournitures de planches d’ambiance. Nous vous accompagnons dans la création des ambiances de votre intérieur."}
                />
                <Phase
                    title={"PHASE 4 : Shopping déco"}
                    prices={["280€/ demie journée (4h)"]}
                    description={"Si vous souhaitez être accompagné durant l’achat de vos éléments de déco (peinture, papier peint, revêtement de sol, objets déco...), nous sillonnerons ensemble les magasins spécialisés. Nous vous assistons dans le choix de vos produits dans l’optique Coût-Qualité-Esthétique."}
                />
                <Phase
                    title={"PHASE 5 : Consultation des entreprises et suivi du chantier"}
                    prices={["8% du montant des travaux"]}
                    description={"Nous vous fournirons un descriptif et un estimatif sommaire des travaux envisagés. Nous consulterons les entreprises les plus aptes à réaliser vos travaux et suivrons l’avancement de votre projet jusqu’à la réception finale des travaux."}
                />

                <ExampleList prestationsExample={prestationsExample} />

                <Phase
                    title={"ANALYSE FENG SHUI"}
                    prices={["450€"]}
                    description={"Que ce soit avant un achat immobilier pour vous guider dans votre choix, avant une construction pour agencer votre future maison, pour votre lieu d’habitation ou votre local professionnel, l‘analyse Feng Shui permet le diagnostic d’un lieu.\n • L’analyse comprend une visite à domicile pour un entretien et des relevés. Nous définissons ensemble vos attentes, vos besoins, les éventuels blocages ou difficultés que vous rencontrez.\n • Je procède ensuite à l’analyse du lieu, en examinant la circulation des énergies dans votre espace, les qualités et les failles du lieu. J’étudie aussi les zones les plus idéales pour chaque usager afin que chacun puisse profiter au mieux des énergies du lieu.\n • Enfin je vous remets un dossier de compte-rendu, dans lequel je vous fais part de mes conseils et solutions pour répondre aux objectifs préalablement fixés"}
                />

                <LastPhase>
                    <LastPhaseTitle>VOUS ÊTES UN PROFESSIONNEL DU BÂTIMENT ET SOUHAITEZ UN PARTENARIAT PONCTUEL POUR UN PROJET<FalseInterrogation>?</FalseInterrogation></LastPhaseTitle>
                    <PhaseDescription>Que ce soit pour une prestation de décoration d’intérieur ou de Feng Shui, nous travaillerons ensemble sur un projet à quatre mains afin de satisfaire au mieux vos clients. Montant de cette prestation de service sur devis.</PhaseDescription>
                </LastPhase>
            </Content>

            <Footer />
        </>
    )
}

export default Prestations