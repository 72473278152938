import { useEffect } from 'react';
import Header from '../header'
import Footer from '../footer'
import styled from 'styled-components'
import Title from '../common/title'
import Content from '../common/content'
import { device } from '../../helpers/breakpoints'
import backgroundImage from '../images/accueil-image.jpg'
import backgroundImageMobile from '../images/accueil-image-mobile.jpg'
import FalseInterrogation from '../common/interrogation'

const Question = styled.div`
    margin-bottom: 32px;
`

const QuestionTitle = styled.h3`
    font-family: "century gothic";
    color: #E2A246;
    font-size: 4.5vw;
    margin: 0;
    padding: 0;
    font-weight: 1000;

    @media ${device.tablet} {
        font-size: 20px;
    }
`

const QuestionBody = styled.p`
    margin: 0;
    padding: 0;
    font-family: "century gothic";
    color: #35626A;
    font-size: 5vw;
    font-weight: lighter;
    text-align: justify;
    text-align-last: center;

    @media ${device.tablet} {
        font-size: 20px;
    }
`

const ProjectPresentationWrapper = styled.div`
    text-align: center;
    margin-top: 15vw;
    margin-bottom: 15vw;
    
    @media ${device.tablet} {
        margin-top: 128px;
        margin-bottom: 150px;
    }
`

const ProjectPresentation = styled.div`
    display: inline-block;
    border-right: 2px solid #b83b35;
    border-left: 2px solid #b83b35;
    padding-right: 8vw;
    padding-left: 8vw;
    margin-right: 3vw;
    margin-left: 3vw;
`

const ProjectPresentationIntro = styled.div`
    font-family: "century gothic";
    color: #E2A246;
    font-size: 5vw;
    font-weight: lighter;
    font-style: italic;
    text-align: left;
    margin-bottom: 20px;

    @media ${device.tablet} {
        font-size: 20px;
    }
`

const ProjectPresentationBody = styled.div`
    font-family: "century gothic";
    color: #35626A;
    font-size: 5vw;
    font-weight: lighter;
    text-align: justify;

    @media ${device.tablet} {
        font-size: 20px;
    }
`

const InterventionWrapper = styled.div`
    text-align: center;
    margin-top: 15vw;
    margin-bottom: 15vw;
    
    @media ${device.tablet} {
        margin-top: 128px;
        margin-bottom: 150px;
    }
`

const Intervention = styled.div`
    display: inline-block;
    border: 2px solid #b83b35;
    padding: 20px 10px 20px 10px;
    margin: 0;
    min-width: auto;

    @media ${device.tablet} {
        padding: 50px 5vw 50px 5vw;
        margin-right: 3vw;
        margin-left: 3vw;
        min-width: calc(100% - 16vw);
    }
`

const InterventionTitle = styled.h2`
    padding: 0;
    margin: 0;
    font-family: "colombia regular";
    font-size: 7.5vw;
    color: #E2A246;
    text-align: center;
    font-weight: lighter;

    @media ${device.tablet} {
        font-size: 35px;
    }
`

const InterventionBody = styled.p`
    padding: 0;
    margin: 0;
    font-family: "century gothic";
    color: #35626A;
    font-size: 5vw;
    font-weight: lighter;
    text-align: center;

    @media ${device.tablet} {
        font-size: 20px;
    }
`

function Accueil() {
    useEffect(() => {
        document.title = "Accueil, Aménagement et décoration d’intérieur bien-être"
     }, []);

    return (
        <>
            <Header backgroundImage={backgroundImage} backgroundImageMobile={backgroundImageMobile} />
            <Content>
                <Title>AMÉNAGEMENT ET DÉCORATION D’INTÉRIEUR BIEN-ÊTRE.</Title>

                <Question>
                    <QuestionTitle>Pourquoi faire appel à un designer / décorateur d’intérieur<FalseInterrogation>?</FalseInterrogation></QuestionTitle>
                    <QuestionBody>
                        Pour trouver des <b>solutions d’aménagement</b>, vous aider à créer un intérieur correspondant à vos goûts et 
                        aux besoins de la vie, pour dynamiser et rafraîchir un espace personnel ou professionnel. Le designer a un 
                        regard technique, esthétique et ergonomique. À votre écoute et à votre service, il concrétise vos envies 
                        et vous aide à créer un <b>intérieur qui vous correspond</b>.
                    </QuestionBody>
                </Question>

                <Question>
                    <QuestionTitle>Pourquoi faire appel à un conseiller Feng Shui<FalseInterrogation>?</FalseInterrogation></QuestionTitle>
                    <QuestionBody>
                        Le Feng Shui est une science ancestrale chinoise, puisant ses principes dans la philosophie taoïste, selon 
                        laquelle nous faisons partie d’un tout, où règnent interdépendance et harmonie. Cette discipline s’intéresse 
                        à l’<b>impact des lieux sur le vivant</b> et pose ainsi la question de l’interaction entre un individu et le lieu qu’il 
                        habite. La pratique de cet art ancestral a pour but de <b>vivre en harmonie avec son environnement</b>, en 
                        équilibrant le flux d’énergie dans les lieux de vie et de travail. Le Feng Shui permet de créer des espaces 
                        fastes, favorisant votre <b>bien-être</b> ou celui de vos clients.
                    </QuestionBody>
                </Question>

                <ProjectPresentationWrapper>
                    <ProjectPresentation>
                        <ProjectPresentationIntro>
                            Vous emménagez et désirez remettre ce nouvel intérieur à votre goût<FalseInterrogation>?</FalseInterrogation><br />
                            Vous désirez être accompagné dans un projet de réaménagement de votre local 
                            professionnel<FalseInterrogation>?</FalseInterrogation><br />
                            Vous ne vous sentez pas bien dans votre intérieur<FalseInterrogation>?</FalseInterrogation>
                        </ProjectPresentationIntro>
                        <ProjectPresentationBody>
                            Que vous soyez particulier ou professionnel, que vous ayez besoin d’un <b> simple conseil ou bien d’un projet de rénovation complet</b>, LP Création
                            est là pour vous accompagner dans vos projets. À votre écoute et alliant 
                            l’œil de designer à la sensibilité de conseiller Feng Shui, LP Création vous 
                            accompagne dans une <b>prestation personnalisée et adaptée à vos besoins</b>. 
                        </ProjectPresentationBody>
                    </ProjectPresentation>
                </ProjectPresentationWrapper>

                <InterventionWrapper>
                    <Intervention>
                        <InterventionTitle>Intervention en Haute-Garonne (31), Tarn (81) et Aude (11)</InterventionTitle>
                        <InterventionBody>
                            Pour toute intervention hors secteur, merci de me contacter
                        </InterventionBody>
                    </Intervention>
                </InterventionWrapper>
            </Content>

            <Footer />
        </>
    )
}

export default Accueil