import React from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Accueil, Contact, Prestations, QuiSuisJe, Realisations, RealisationDetail } from './components/routes'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/accueil">
          <Accueil />
        </Route>
        <Route path="/qui-suis-je">
          <QuiSuisJe />
        </Route>
        <Route path="/prestations">
          <Prestations />
        </Route>
        <Route path="/realisations/:id" children={<RealisationDetail />} />
        <Route path="/realisations">
          <Realisations />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/">
          <Accueil />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
